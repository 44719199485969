<template>
  <div class="success-case">
    <div class="title">获客效果说明（单店年度数据）</div>
    <div class="sub-title">婚礼堂接入获客系统后，场均婚礼获客2.36单</div>
    <div class="card-wrap">
      <div class="card" v-for="(item, index) in topList" :key="index">
        <img :src="item.icon" alt="" class="icon" />
        <div class="show">
          {{ item.top }} <span class="high">{{ item.high }}</span>
        </div>
        <div class="label">{{ item.desc }}</div>
      </div>
    </div>
  </div>
</template>

<script>
const topList = [
  {
    top: '·单店年度吸粉 ',
    high: '50万+',
    desc: '·店铺公众号吸粉再也不担心',
    icon: require('@/assets/hlt/pool-effect/a.png'),
  },
  {
    top: '·客户咨询量  ',
    high: '8000+',
    desc: '·每年上万条客户线索，数千个客户咨询',
    icon: require('@/assets/hlt/pool-effect/b.png'),
  },
  {
    top: '·成交订单数  ',
    high: '1000+',
    desc: '·帮助店铺年盈利增长数千万',
    icon: require('@/assets/hlt/pool-effect/c.png'),
  },
];
export default {
  name: 'SuccessCase',
  data() {
    return {
      topList,
    };
  },
};
</script>

<style lang="less" scoped>
.success-case {
  height: 565px;
  background: #fff;
  padding-top: 100px;
  box-sizing: border-box;
  div {
    box-sizing: border-box;
  }
  .title {
    margin: 0 auto;
    text-align: center;
    font-size: 28px;
    font-weight: 500;
  }
  .sub-title {
    margin: 0 auto;
    margin-top: 16px;
    text-align: center;
    color: #889099;
    font-size: 14px;
  }

  .card-wrap {
    width: 100%;
    // height: 460px;
    // background: gray;
    margin: 34px 0;
    padding: 0 300px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .card {
      margin-right: 24px;
      width: 360px;
      height: 240px;
      background: #f8f9fc;
      &:last-child {
        margin-right: 0px;
      }
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .icon {
        width: 70px;
        height: 70px;
        // background: red;
      }
      .top {
        margin-top: 32px;
        color: #333333;
        font-size: 16px;
      }
      .high {
        // margin-top: 8px;
        color: #157bec;
        font-size: 24px;
      }
      .desc {
        margin-top: 8px;
        color: #333333;
        font-size: 16px;
      }
    }
  }
}
</style>
