<template>
  <div class="success-case">
    <div class="title">成功案例</div>
    <div class="sub-title">500+婚礼堂酒店选择嗨喵</div>
    <div class="card-wrap">
      <el-carousel :autoplay="true" :interval="4000" type="card" style="width: 1128px">
        <!-- height="1.10417rem"
        style="width: 1328px" -->
        <el-carousel-item v-for="(item, index) in topList" :key="index">
          <div class="card-wrap">
            <div class="img-wrap">
              <img :src="item.img" alt="" srcset="" class="card" />
            </div>
            <h3 class="medium">
              {{item.text}}
            </h3>
          </div>
        </el-carousel-item>
      </el-carousel>
      <!-- <div class="card" v-for="(item, index) in topList" :key="index"></div> -->
    </div>
  </div>
</template>

<script>
const topList = [
  {
    img: require('@/assets/hlt/success-case/a.jpg'),
    text: 'A股上市公司同庆楼签约嗨喵，第一次婚宴现场即获客',
  },
  {
    img: require('@/assets/hlt/success-case/b.jpg'),
    text: 'A股上市公司同庆楼签约嗨喵，第一次婚宴现场即获客',
  },
  {
    img: require('@/assets/hlt/success-case/c.jpg'),
    text: 'A股上市公司同庆楼签约嗨喵，第一次婚宴现场即获客',
  },
];

export default {
  name: 'SuccessCase',
  data() {
    return {
      topList,
    };
  },
};
</script>

<style lang="less" scoped>
.success-case {
  height: 635px;
  background: #fff;
  // background: red;
  padding-top: 60px;
  box-sizing: border-box;
  div {
    box-sizing: border-box;
  }
  .title {
    margin: 0 auto;
    text-align: center;
    font-size: 28px;
    font-weight: 500;
  }
  .sub-title {
    margin: 0 auto;
    margin-top: 16px;
    text-align: center;
    color: #889099;
    font-size: 14px;
  }

  .card-wrap {
    width: 100%;
    // width: 386px;
    // height: 460px;
    // background: gray;
    margin: 34px 0;
    // padding: 0 300px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    @rate: 1.4;
    .img-wrap{
      width: 386px * @rate;
      height: 212px * @rate;
    }
    .medium{
      width: 386px * @rate;
      height: 66px * @rate;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #F3F6FA;
      font-size: 14px;
      color: #333333;
      // height: 212px;
    }
    .card {
      width: 386px * @rate;
      height: 212px  @rate;
      background: #ffffff;
      border-radius: 12px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      margin-bottom: 24px;
      margin-right: 24px;
      box-shadow: 0px 3px 12px 0px rgba(86, 164, 255, 0.08);
      background: #167bed;
    }
  }
}
</style>
