<template>
  <div class="maincontainer">
    <banner></banner>
    <pool-effect></pool-effect>
    <four-intro></four-intro>
    <product-strength></product-strength>

    <time-protect></time-protect>
    <success-case></success-case>
    <user-acknowledge></user-acknowledge>
    <concat-us></concat-us>
    <use-immediate></use-immediate>
    <about-us></about-us>
    <melon-footer></melon-footer>
    <toolBox @openQrcodeBox="openQrcodeBox" />
  </div>
</template>

<script>
import banner from '@/components/layout/banner.vue';

import MelonFooter from '@/components/layout/MelonFooter.vue';
import toolBox from '@/components/toolBox.vue';
import AboutUs from './components/AboutUs.vue';
import UseImmediate from './components/UseImmediate.vue';
import ConcatUs from './components/ConcatUs.vue';
import UserAcknowledge from './components/UserAcknowledge.vue';
import SuccessCase from './components/SuccessCase.vue';
import TimeProtect from './components/TimeProtect.vue';
import ProductStrength from './components/ProductStrength.vue';
import PoolEffect from './components/PoolEffect.vue';
import FourIntro from './components/FourIntro.vue';

export default {
  components: {
    banner,

    FourIntro,
    PoolEffect,
    ProductStrength,
    TimeProtect,
    SuccessCase,
    UserAcknowledge,
    ConcatUs,
    UseImmediate,
    AboutUs,
    MelonFooter,
    toolBox,
  },
  methods: {
    openQrcodeBox() {
      this.qrcodeBoxVisible = true;
    },
  },
};
</script>
<style lang="less" scoped>
.maincontainer {
  width: 100%;
  // overflow: hidden;
}
</style>