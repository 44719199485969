<template>
  <div class="four-intro">
    <div class="title">嗨喵婚礼堂获客系统</div>
    <div class="sub-title">四大功能实现轻松获客</div>
    <div class="card-wrap">
      <div
        class="card"
        v-for="(item, index) in fourList"
        :key="index"
        :class="item.reverse ? 'card-reverse' : ''"
      >
        <img
          :src="item.src"
          :alt="item.title"
          class="card-img"
          v-if="index === 0"
        />
        <img
          :src="item.src"
          :alt="item.title"
          class="card-img"
          v-if="index === 1"
        />
        <div v-if="index === 2" class="card-img">
          <img
            class="c-img"
            v-for="img in item.imgList"
            :src="img"
            :alt="item.title"
            :key="img"
          />
        </div>
        <div v-if="index === 3" class="card-img">
          <img
            class="d-img"
            v-for="img in item.imgList"
            :src="img"
            :alt="item.title"
            :key="img"
          />
        </div>
        <div class="card-intro">
          <div class="card-intro__title">
            {{ item.title }}
          </div>
          <div class="card-intro__desc">
            {{ item.desc }}
          </div>
          <div class="card-intro__btn">
            {{ item.btn }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const fourList = [
  {
    title: '1.大屏互动聚集现场流量',
    desc: '·嗨喵大屏互动聚焦现场来宾亲友，流量瞬间聚集',
    btn: '体验大屏互动Demo',
    src: require('@/assets/hlt/four-intro/a.jpg'),
  },
  {
    title: '2.吸粉功能助力粉丝增长',
    desc: '·将现场流量转为公众号粉丝，构建私域流量池',
    btn: '体验大屏互动Demo',
    reverse: true,
    src: require('@/assets/hlt/four-intro/b.jpg'),
  },
  {
    title: '3.线上店铺展示品牌业务',
    desc: '·顾客很方便通过视频、图文等多种形式，全方位 了解婚礼堂场布置、酒席等产品信息，为品牌助力',
    btn: '体验大屏互动Demo',
    imgList: [
      require('@/assets/hlt/four-intro/c1.png'),
      require('@/assets/hlt/four-intro/c2.png'),
      require('@/assets/hlt/four-intro/c3.png'),
    ],
  },
  {
    title: '4.智能运营引导顾客咨询',
    desc: '·通过抽奖、领券、赠礼等 多种自动化运营手段，吸引客户主动电话咨询',
    btn: '体验大屏互动Demo',
    reverse: true,
    imgList: [
      require('@/assets/hlt/four-intro/d1.png'),
      require('@/assets/hlt/four-intro/d2.png'),
      require('@/assets/hlt/four-intro/d3.png'),
      require('@/assets/hlt/four-intro/d4.png'),
    ],
  },
];
export default {
  name: 'FourIntro',
  data() {
    return {
      fourList,
    };
  },
};
</script>

<style lang="less" scoped>
.four-intro {
  // height: 615px;
  background: #f3f6fa;
  padding-top: 100px;
  padding-bottom: 100px;
  box-sizing: border-box;
  div {
    box-sizing: border-box;
  }
  .title {
    margin: 0 auto;
    text-align: center;
    font-size: 28px;
    font-weight: 500;
  }
  .sub-title {
    margin: 0 auto;
    margin-top: 16px;
    text-align: center;
    color: #889099;
    font-size: 14px;
  }
  .card-wrap {
    margin-top: 50px;
    .card {
      margin-bottom: 100px;
      &:last-child {
        margin-bottom: 0;
      }
      display: flex;
      justify-content: center;
      align-items: center;
      &-reverse {
        flex-direction: row-reverse;
        .card-img {
          margin-left: 80px;
        }
        .card-intro {
          align-items: flex-end;
        }
      }
      &-img {
        min-width: 615px;
        height: 395px;
        // background: red;
        margin-right: 80px;
        position: relative;
        .c-img {
          width: 194px;
          height: 386px;
          margin-left: 7px;
        }
        .d-img {
          width: 194px;
          height: 386px;
          position: absolute;
          // margin-right: -40px;
          &:nth-child(1) {
            left: 0px;
          }
          &:nth-child(2) {
            left: 142px;
          }
          &:nth-child(3) {
            left: calc(142px + 142px);
          }
          &:nth-child(4) {
            left: calc(142px + 142px + 142px);
          }
        }
      }
      &-intro {
        width: 400px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        &__title {
          color: #333333;
          font-size: 24px;
          font-weight: 500;
        }
        &__desc {
          margin-top: 20px;
          color: #333333;
          font-size: 16px;
        }
        &__btn {
          margin-top: 40px;
          width: 168px;
          height: 42px;
          background: #167bed;
          font-size: 14px;
          color: #fff;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
