<template>
  <div v-if="false" class="user-acknowledge">
    <div class="title">众多合作伙伴的一致认可</div>
    <div class="sub-title">感谢每一位合作伙伴的信任</div>
    <div class="card-wrap">
      <div class="card" v-for="(item, index) in topList" :key="index">

      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UserAcknowledge',
  data() {
    return {
      topList: new Array(8)
    }
  },
};
</script>

<style lang="less" scoped>
.user-acknowledge {
  height: 589px;
  background: #fff;
  padding-top: 60px;
  box-sizing: border-box;
  div {
    box-sizing: border-box;
  }
  .title {
    margin: 0 auto;
    text-align: center;
    font-size: 28px;
    font-weight: 500;
  }
  .sub-title {
    margin: 0 auto;
    margin-top: 16px;
    text-align: center;
    color: #889099;
    font-size: 14px;
  }

  .card-wrap {
    // height: 460px;
    // background: gray;
    margin: 34px 0;
    padding: 0 300px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    .card {
      width: 264px;
      height: 148px;
      background: #ffffff;
      border-radius: 12px;
      margin-bottom: 24px;
      margin-right: 24px;
      box-shadow: 0px 3px 12px 0px rgba(86, 164, 255, 0.08);
      background: #167bed;
    }
  }

}
</style>
