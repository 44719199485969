<template>
  <div class="success-case">
    <div class="title">全方位运营护航</div>
    <div class="sub-title">嗨喵运营团队给予客户最大的运营与技术支持</div>
    <div class="card-wrap">
      <div class="card" v-for="(item, index) in topList" :key="index">
        <!-- <img src="" alt="" class="icon" /> -->
        <img :src="item.icon" alt="" class="icon" :class="item.class" />

        <div class="name">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
const topList = [
  {
    name: '专业的客户培训团队',
    icon: require('@/assets/hlt/time-product/a.png'),
    class: 'a',
  },
  {
    name: '强大的技术保障',
    icon: require('@/assets/hlt/time-product/b.png'),
    class: 'b',
  },
  {
    name: '7*24小时客服支持',
    icon: require('@/assets/hlt/time-product/c.png'),
    class: 'c',
  },
];
export default {
  name: 'SuccessCase',
  data() {
    return {
      topList,
    };
  },
};
</script>

<style lang="less" scoped>
.success-case {
  height: 615px;
  background: #f3f6fa;
  padding-top: 110px;
  padding-bottom: 100px;
  box-sizing: border-box;
  div {
    box-sizing: border-box;
  }
  .title {
    margin: 0 auto;
    text-align: center;
    font-size: 28px;
    font-weight: 500;
  }
  .sub-title {
    margin: 0 auto;
    margin-top: 16px;
    text-align: center;
    color: #889099;
    font-size: 14px;
  }

  .card-wrap {
    width: 100%;
    // height: 460px;
    // background: gray;
    margin: 34px 0;
    padding: 0 300px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .card {
      margin-right: 24px;
      width: 360px;
      height: 278px;
      background: #ffffff;
      border-radius: 4px;
      box-shadow: 0px 4px 9px 0px rgba(86, 164, 255, 0.16);
      &:last-child {
        margin-right: 0px;
      }
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .icon {
        width: 92px;
        height: 85px;
        &.a {
          width: 92px;
          height: 118px;
        }
        &.b {
          width: 92px;
          height: 86px;
        }
        &.c {
          width: 84px;
          height: 85px;
        }
        // background: red;
      }
      .name {
        margin-top: 60px;
        color: #333333;
        font-size: 20px;
      }
    }
  }
}
</style>
