<template>
  <div class="product-strength">
    <div class="title">产品优势</div>
    <div class="sub-title">功能强大丰富，赋能婚礼堂增长获客</div>
    <div class="box-wrap">
      <div class="menu-wrap">
        <div class="menu-box" :class="currentIndex === 0 ? 'active' : ''" @mouseenter="moveOnIt" data-index="0">
          <div class="menu-box__title">行业首创，专为婚礼堂量身打造</div>
          <div class="menu-box__strlist">
            <div class="menu-box__stritem">·大屏互动+私域流量+智能营销</div>
            <div class="menu-box__stritem">·嗨喵科技历经十数次产品迭代</div>
            <div class="menu-box__stritem">·开辟婚礼堂线上获客新渠道</div>
          </div>
        </div>
        <div class="menu-box" :class="currentIndex === 1 ? 'active' : ''" @mouseenter="moveOnIt" data-index="1">
          <div class="menu-box__title">会员获取率高</div>
          <div class="menu-box__strlist">
            <div class="menu-box__stritem">
              ·大屏互动现场气氛热烈，人人参与互动
            </div>
            <div class="menu-box__stritem">·会员转化率高达95%</div>
            <div class="menu-box__stritem">·轻松构建私域流量池</div>
          </div>
        </div>
        <div class="menu-box" :class="currentIndex === 2 ? 'active' : ''" @mouseenter="moveOnIt" data-index="2">
          <div class="menu-box__title">数字化智能运营获客</div>
          <div class="menu-box__strlist">
            <div class="menu-box__stritem">·AI智能筛选意向客户</div>
            <div class="menu-box__stritem">·用户行为、停留时长全展现</div>
            <div class="menu-box__stritem">·帮助管理人员挖掘更多客户价值</div>
          </div>
        </div>
      </div>
      <div class="menu-box-wrap">
        <div class="menu-img"></div>
      </div>
    </div>
  </div>
</template>

<script>
// import { ENTERPRISE } from '@/assets/constant/default';
import { ENTERPRISE } from '@/assets/constant/default';

export default {
  data() {
    return {
      currentIndex: 0,
    };
  },
  methods: {
    changeIndex(i) {
      this.currentIndex = i;
    },
    moveOnIt(e) {
      const { index } = e.target.dataset;
      this.changeIndex(Number(index));
    },
    trial() {
      window.open(ENTERPRISE, '_blank');
    },
  },
};
</script>

<style lang="less" scoped>
.product-strength {
  // height: 767px;
  background: #fff;
  padding-top: 100px;
  box-sizing: border-box;
  height: 773px;
  // background: rgba(0, 0, 0);
  background-image: url("~@/assets/hlt/product-strength/bg.jpg");
  background-size: 100% 100%;
  color: #ffffff;
  div {
    box-sizing: border-box;
  }
  .title {
    margin: 0 auto;
    text-align: center;
    color: #ffffff;
    font-weight: 600;
    font-size: 28px;
  }
  .sub-title {
    margin: 0 auto;
    margin-top: 24px;
    text-align: center;
    font-size: 20px;
    color: #ffffff;
  }
  .box-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    .menu-wrap {
      width: 356px;
      // background: #fff;
      height: 440px;
      margin-right: 24px;

      .menu-box {
        width: 356px;
        height: 136px;
        background: #292929;
        border-radius: 4px;
        margin-top: 16px;
        cursor: pointer;
        &:first-child {
          margin-top: 0px;
        }
        &.active {
          background: #167bed;
        }
        padding: 20px;
        &__title {
          color: #ffffff;
          font-size: 20px;
          font-weight: 500;
        }
        &__strlist {
          margin-top: 8px;
        }
        &__stritem {
          color: #ffffff;
          font-size: 14px;
        }
      }
    }
    .menu-box-wrap {
      width: 748px;
      height: 440px;
      background: #292929;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      .menu-img {
        width: 716px;
        height: 408px;
        background-image: url("~@/assets/hlt/product-strength/box.jpg");
        background-size: 100% 100%;
        border-radius: 5px;
      }
    }
  }
}
</style>
