<template>
  <div class="concat-us">
    <div class="concat-box">
      <div class="basic-box">
        <div class="h1">立即致电客户经理</div>
        <div class="h2">拨打咨询电话，立即联系客户经理</div>
        <img
          class="kf"
          :src="require('@/assets/hlt/concat-us/kf.png')"
          alt="客服图片"
        />
        <div class="phone">+86 19512368757</div>
        <div class="worktime">工作时间 (00:00-24:00)</div>
      </div>
      <div class="line"></div>
      <div class="basic-box">
        <div class="h1">留下您的联系方式</div>
        <div class="h2">咨询顾问会在3小时内致电讲解（非工作日稍有延时）</div>
        <div class="form-item-wrap">
          <input
            type="text"
            class="form-input"
            placeholder="您的姓名"
            v-model="content"
          />
          <input
            type="text"
            class="form-input"
            placeholder="您的电话"
            v-model="phone"
          />
          <input
            type="text"
            class="form-input"
            placeholder="您的地区"
            v-model="remark"
          />

          <div class="btn" @click="submit">预约咨询顾问</div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { putRecord } from '@/api/index';

export default {
  name: 'ConcatUs',
  data() {
    return {
      remark: '',
      phone: '',
      content: '',
    };
  },
  created() {},
  methods: {
    submit() {
      const { remark, phone, content } = this;
      if (!content) {
        this.$message.error('请填写姓名');
        return;
      }
      if (!phone) {
        this.$message.error('请填写电话');
        return;
      }
      if (phone.length !== 11) {
        this.$message.error('请填写合法的电话');
        return;
      }
      if (!remark) {
        this.$message.error('请描述您的需求');
        return;
      }
      console.log(remark, phone, content);
      putRecord({ remark, phone, content })
        .then((res) => {
          this.$message.success('提交成功！');

          console.log(res);
        })
        .catch((err) => {
          this.$message.error('提交失败！');

          console.log(err);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.concat-us {
  background: #f3f6fa;
  box-sizing: border-box;
  height: 586px;
  color: #ffffff;
  padding: 100px 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  .concat-box {
    width: 936px;
    height: 395px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 2.54px 10.15px 0px rgba(86, 164, 255, 0.08);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    .line {
      width: 1px;
      height: 264px;
      background: #f3f6fa;
      // background: red;
    }
    .basic-box {
      flex: 1;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      .h1 {
        color: #333333;
        font-size: 24px;
        font-weight: 500;
      }
      .h2 {
        color: rgba(0, 0, 0, 0.6);
        font-size: 14px;
        margin-top: 14px;
      }
      .kf {
        margin-top: 29px;
        width: 118px;
        height: 118px;
        border-radius: 100%;
      }
      .phone {
        margin-top: 22px;
        color: #333333;
        font-size: 20px;
        font-weight: 500;
      }
      .worktime {
        color: rgba(0, 0, 0, 0.6);
        font-size: 14px;
      }
    }
  }
}
.form-item-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  .form-input {
    box-sizing: border-box;
    margin-top: 16px;
    width: 260px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #dcdee0;
    border-radius: 4px;
    padding-left: 12px;

    font-size: 14px;
    // color: #fff;
    &::placeholder {
      // color: #fff;
      color: rgba(0, 0, 0, 0.4);
    }
  }
  .form-textarea {
    margin-top: 17px;
    padding-top: 17px;
    width: 448px;
    height: 179px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    box-sizing: border-box;
    padding: 16px;
    color: #fff;
    &::placeholder {
      color: #fff;
    }
  }
  .plac {
    color: #fff;
  }
  .btn {
    cursor: pointer;
    width: 260px;
    height: 42px;
    background: #167bed;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
  }
}
</style>
